const gallerydata =[
    {
        id:1,
        images:"https://htmldemo.net/drior/drior/assets/images/project/project-2.jpg",
    name1:"Coffee & Restaurant Interior Design",
name2:"fbfg"  ,
category:"Interior"  
},
{
    id:2,
   images:"	https://htmldemo.net/drior/drior/assets/images/project/project-2.jpg",
    name1:"Coffee & Restaurant Interior Design",
    name2:"Building" ,
    category:"Architecture " 
},
{
    id:3,
    images:"https://htmldemo.net/drior/drior/assets/images/project/project-2.jpg",
    name1:"Coffee & Restaurant Interior Design",
    name2:"Interior",
    category:"Design"  
},
{
    id:4,
    images:"https://htmldemo.net/drior/drior/assets/images/project/project-2.jpg",
    name1:"Coffee & Restaurant Interior Design",
    name2:"Intaserior" ,
    category:"Building" 
}
];
export default gallerydata