import React from 'react';
import "../css/buttion.css"
import { BsWhatsapp } from 'react-icons/bs';

const What = () => {
    return (
        <>
            <div className='CallBtn-con'>
                <div className='CallBtn'>
                    <a href="https://wa.me/9920706747?text=" target='blank'>
                        <BsWhatsapp/>
                    </a>
                </div>
            </div>
        </>
    )
}

export default What