import Hadder from "../Components/Hadder";
import Fotter from "../Components/fotter";
import NumberCounter from "number-counter";
import aa1 from "../assets/aa11.png";
import h7 from "../assets/h1.png";
import aa5 from "../assets/aa1.png";
import h2 from "../assets/homebackground.jpg"
import aa2 from "../assets/aa2.png";
import aa3 from "../assets/aa3.png";
import aa4 from "../assets/aa4.png";
import { Link } from "react-router-dom";
import {LiaPlaySolid } from 'react-icons/lia';
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { MetaTags } from 'react-meta-tags';
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Aboutus = () => {
  return (
    <div>
      <Hadder></Hadder>
      <MetaTags>
        <title> Best Interior Designers In Mira Road |Shree Vallabh Interior</title>
        <meta title=" Best Interior Designers In Mira Road |Shree Vallabh Interior" />
        <meta name="description" content="Looking for the Best Interior Designers In Mira Road? Shree Vallabh Interior expert home stylists create captivating spaces that reflect your unique style." />
        <meta name="keywords" content="indias-best-celebrity-management-agency-mumbai,event-management-company-andheri,celebrity-management-company-andheri,top-event-management-company-andheri,best-celebrity-management-company-in-andheri" />
        <link rel="canonical" href="https://shreevallabhinterior.in/best-interior-designers-mira-road" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Best Interior Designers In Mira Road |Shree Vallabh Interior" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shreevallabhinterior.in/" />
        <meta property="og:description" content="Looking for the Best Interior Designers In Mira Road? Shree Vallabh Interior expert home stylists create captivating spaces that reflect your unique style." />
        <meta property="og:image" content="https://shreevallabhinterior.in/static/media/LOGO.1844ee8b9261e65cff58.png" />
      </MetaTags>
      <div
        className="breadcrumb-area breadcrumb-padding bg-img"
        style={{
          backgroundImage:
            "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-2.jpg)",
        }}
      >
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 style={{color:"white"}}>Best Interior Designers In Mira Road</h1>
            {/* <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right" />
              </li>
              <li>About Us</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="row row-cols-lg-2 row-cols-1 mb-n6">
            <div className="col-md-6 align-self-center mb-6 order-md-1 order-2">
              <div className="about-content margintop10">
                <h2 className="title textalign">
                  Welcome to <span>Shree vallabh Interior </span>
                </h2>
                <p className="textalign">
                  We the Best Interior Designers in Mira Road craft interiors
                  from concept to reality curating comfort and captivating
                  aesthetics , where every room is a canvas and your dreams are
                  the masterpiece.
                </p>
              </div>
              <div className="working-away-wrap">
                <div className="working-away">
                  <div>
                    <h3 className="title textalign">
                      Residential Interior Designing{" "}
                    </h3>
                    <p className="textalign">
                      Elevating your living spaces with our personalised
                      residential interior designs solutions by creating
                      environments with comfort and aesthetics.
                    </p>
                  </div>
                </div>
                <div className="working-away">
                  <div>
                    <h3 className="title textalign">
                      Commercial Interior Designing
                    </h3>
                    <p className="textalign">
                      Making a lasting impression with our transformative
                      commercial interior design services by understanding the
                      importance of functional and visually appealing spaces.
                    </p>
                  </div>
                </div>
              </div>
              <div className="textalign">
               
              </div>
            </div>
            <div className="col-md-6 mb-6 order-md-2 order-1">
              <div className="about-banner">
                <img src={aa1} alt="17 YEARS OF EXPERIENCE in interior design" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section section-padding bg-img overly-style-1 opacity-point-7"
        style={{
          backgroundImage:
            "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-1.jpg)",
        }}
      >
        <div className="container">
          <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={aa5} alt="Our Client"/>
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={594}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={500}
                                delay={4}
                                className="displayflex"
                              />
                              +
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Our Client</h4>
              </div>
            </div>
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={aa2} alt="Reviews" />
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={100}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={254}
                                delay={4}
                                className="displayflex"
                              />
                              +
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Reviews</h4>
              </div>
            </div>
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={aa3} alt="Lowest Price"/>
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={785}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={50}
                                delay={4}
                                className="displayflex"
                              />
                              k
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Lowest Price</h4>
              </div>
            </div>
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={aa4} alt="Support Given" />
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={542}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={578}
                                delay={4}
                                className="displayflex"
                              />
                              +
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Support Given</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
     
   
<div className="contectus">
        <div className="container">
        <div className="row" style={{padding:"20px"}}>
          <div className="col-md-8" style={{padding:"20px"}}>
            <h3>
          <div className="bannertext">
        <div className="animate one">
        <span>"G</span>
        <span>a</span>
        <span>i</span>
        <span>n</span>
        <span>i</span>
        <span>n</span>
        <span>g</span>
        <span className='me-2'></span>
        <span>I</span>
        <span>n</span>
        <span>s</span>
        <span>i</span>
        <span>g</span>
        <span>h</span>
        <span>t</span>
        <span>s</span>
        <span className='me-2'></span>
        <span>L</span>
        <span>e</span>
        <span>n</span>
        <span>s</span>
        <span className='me-2'></span>
        <span>T</span>
        <span>h</span>
        <span>e</span>
        <span className='me-2'></span>
        <span>V</span>
        <span>i</span>
        <span>e</span>
        <span>w</span>
        <span className='me-2'></span>
        <span>P</span>
        <span>r</span>
        <span>o</span>
        <span>j</span>
        <span>e</span>
        <span>c</span>
        <span>T."</span>
 
      </div>
        </div>
        </h3>
          </div>
          
          <div className="col-md-4" style={{padding:"20px"}}>
          <a
     
     className="btn btn-primary btn-hover-dark aos-init aos-animate"
     data-aos="fade-up"
     data-aos-delay={400}
   >
    <Link to="/residential-interior-designer-mira-road">VIEW PROJECT</Link>
     
   </a>
          </div>
        </div>
        </div>

      </div>
      <div
        className="section section-padding bg-img overly-style-1 opacity-point-7"
        style={{
          backgroundImage:
            "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-1.jpg)",
        }}
      >
        <div className="container">
          <div className="section-title text-center mb-lg-9 mb-md-7 mb-5">
            <h2 className="title white">
              WHAT <span> CLIENTS</span> SAY?
            </h2>
            <p className="white">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text.
            </p>
          </div>
          <div className="testimonial-active swiper swiper-initialized swiper-horizontal swiper-pointer-events">
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-a4481288903ee2ec"
              aria-live="polite"
            >
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1000: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="Swiper-con">
                    <div
                      className="swiper-slide swiper-slide-duplicate"
                      data-swiper-slide-index={2}
                      role="group"
                      aria-label="3 / 4"
                      // style={{ width: 370 }}
                    >
                      <div className="single-testimonial-wrapper">
                        <div className="testimonial-img-title">
                          <div className="testimonial-img">
                            <img
                              src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-3.png"
                              alt="testimonial"
                            />
                          </div>
                          <div className="testimonial-title">
                            <h4>Emma Hayes</h4>
                            <span>CEO &amp; Founder </span>
                          </div>
                        </div>
                        <p>
                          It is a long established fact that a reader will be
                          distracted the readable content of a page when
                          looking.
                        </p>
                        <div className="quote">
                          <i className="fa fa-quote-left" />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{  width: 370 }}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-1.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Emma Hayes</h4>
                          <span>CEO &amp; Founder </span>
                        </div>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted the readable content of a page when looking.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>
                  {" "}
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{  width: 370 }}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-2.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Emma Hayes</h4>
                          <span>CEO &amp; Founder </span>
                        </div>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted the readable content of a page when looking.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{ width: 370}}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-3.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Emma Hayes</h4>
                          <span>CEO &amp; Founder </span>
                        </div>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted the readable content of a page when looking.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{ width: 370}}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-2.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Emma Hayes</h4>
                          <span>CEO &amp; Founder </span>
                        </div>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted the readable content of a page when looking.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{  width: 370}}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-1.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Emma Hayes</h4>
                          <span>CEO &amp; Founder </span>
                        </div>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted the readable content of a page when looking.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
              </Swiper>
              
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </div>

      <Fotter></Fotter>
    </div>
  );
};

export default Aboutus;
