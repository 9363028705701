import React, { useRef, useState } from 'react';
import Hadder from "../Components/Hadder";
import Fotter from '../Components/fotter';
import gallerydata from "../Components/Gallerydata";
import pre1 from "../assets/project/pre1.jpg"
import pre2 from "../assets/project/pre2.jpg"
import pre3 from "../assets/project/pre3.jpg"
import pre4 from "../assets/project/pre4.jpg"
import pre5 from "../assets/project/pre5.jpg"
import pre6 from "../assets/project/pre6.jpg"
import pre7 from "../assets/project/pre7.jpg"
import { MetaTags } from 'react-meta-tags';
const Project = () => {
  console.log(gallerydata);

const [imagess,setimagess] = useState(gallerydata)
const allitem =()=>{
  const finalData = gallerydata.filter((value)=>{
return value; 
  })
  setimagess(finalData)
}
const onlyInterior =(categoryitem)=>{
const finaldata =gallerydata.filter((value)=>{
  return value.category == categoryitem
})
setimagess(finaldata)
}
  return (
    <div>
      <Hadder></Hadder>
      <MetaTags>
        <title> Residential Interior Designer In Mira Road | Shree Vallabh Interior</title>
        <meta title=" Residential Interior Designer In Mira Road | Shree Vallabh Interior" />
        <meta name="description" content="Transform your place with  expert Residential Interior Designer In Mira Road. Create stunning interiors that reflect your style and personality." />
        <meta name="keywords" content="indias-best-celebrity-management-agency-mumbai,event-management-company-andheri,celebrity-management-company-andheri,top-event-management-company-andheri,best-celebrity-management-company-in-andheri" />
        <link rel="canonical" href="https://shreevallabhinterior.in/residential-interior-designer-mira-road" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Residential Interior Designer In Mira Road | Shree Vallabh Interior" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shreevallabhinterior.in/" />
        <meta property="og:description" content="Transform your place with  expert Residential Interior Designer In Mira Road. Create stunning interiors that reflect your style and personality." />
        <meta property="og:image" content="https://shreevallabhinterior.in/static/media/LOGO.1844ee8b9261e65cff58.png" />
      </MetaTags>
      <div
        className="breadcrumb-area breadcrumb-padding bg-img"
        style={{ backgroundImage: "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-2.jpg)" }}
      >
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 style={{color:"white"}}>Residential Interior Designer In Mira Road</h1>
            {/* <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right" />
              </li>
              <li>Projects</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="section section-padding">
  <div className="container">
   
    <div style={{ position: "relative" }} >
      <div className='row'>
      <div className="col-md-4 mb-6 grid-item cat2 cat4">
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre7} alt="Building" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Building</a>
              </span>
              <h3 className="title">
                <a >Living Room Interior Design</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-6 grid-item cat1">
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre4} alt="Interior" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Interior</a>
              </span>
              <h3 className="title">
                <a >
                  Coffee &amp; Restaurant Interior Design
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
     
     <div className="col-md-4 mb-6 grid-item cat4" >
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre6} alt="Architecture" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Architecture</a>
              </span>
              <h3 className="title">
                <a >Bedroom Remodeling </a>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-6 grid-item cat4" >
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre2} alt="Architecture" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Architecture</a>
              </span>
              <h3 className="title">
                <a >Bedroom Remodeling </a>
              </h3>
            </div>
          </div>
        </div>
      </div>


      <div className="col-md-4 mb-6 grid-item cat4" >
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre5} alt="Architecture" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Architecture</a>
              </span>
              <h3 className="title">
                <a >Bedroom Remodeling </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-6 grid-item cat4" >
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre3} alt="Architecture" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Architecture</a>
              </span>
              <h3 className="title">
                <a >Bedroom Remodeling </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      </div>
    {/*    <div  className="col-md-4 mb-6 grid-item cat3 cat1"
        style={{ position: "absolute", left: "0%", top: 295 }}
      >
        <div className="project-wrap">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src="https://htmldemo.net/drior/drior/assets/images/project/project-4.jpg" alt="Blog Image" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Building</a>
              </span>
              <h3 className="title">
                <a >Window Glass Replacement</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-6 grid-item cat1 cat3"
        style={{ position: "absolute", left: "66.6667%", top: 295 }}
      >
        <div className="project-wrap">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src="https://htmldemo.net/drior/drior/assets/images/project/project-5.jpg" alt="Blog Image" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Architecture</a>
              </span>
              <h3 className="title">
                <a >Bathroom Redesign</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-6 grid-item cat2 cat4"
        style={{ position: "absolute", left: "33.3333%", top: "477.075px" }}
      >
        <div className="project-wrap">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src="https://htmldemo.net/drior/drior/assets/images/project/project-7.jpg" alt="Blog Image" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Interior</a>
              </span>
              <h3 className="title">
                <a >Living Room Remodeling</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-6 grid-item cat4"
        style={{ position: "absolute", left: "66.6667%", top: 590 }}
      >
        <div className="project-wrap">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src="https://htmldemo.net/drior/drior/assets/images/project/project-6.jpg" alt="Blog Image" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Interior</a>
              </span>
              <h3 className="title">
                <a >Office Room Interior Design</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-6 grid-item cat2 cat4"
        style={{ position: "absolute", left: "0%", top: "772.075px" }}
      >
        <div className="project-wrap">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src="https://htmldemo.net/drior/drior/assets/images/project/project-8.jpg" alt="Blog Image" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Building</a>
              </span>
              <h3 className="title">
                <a >
                  Hardwood Flooring Installation
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div  className="col-md-6 mb-6 grid-item cat1"
        style={{ position: "absolute", left: "33.3333%", top: "772.075px" }}
      >
        <div className="project-wrap">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src="https://htmldemo.net/drior/drior/assets/images/project/project-9.jpg" alt="Blog Image" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Interior</a>
              </span>
              <h3 className="title">
                <a >Restaurant Interior Design</a>
              </h3>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  
  </div>
</div>
<Fotter></Fotter>
    </div>
   
  );
};

export default Project;
