import React, { useState } from 'react';
import { Nav, Navbar, Container } from "react-bootstrap";
import "../css/Hadder.css"
import Logo  from "../assets/LOGO.png"
import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
const Hadder = () => {
    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 78) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <>
            <div className="nav-bar-component">
                <section className="NavSection">
                    {/* <div className="container"> */}
                    <Navbar collapseOnSelect expand="lg" variant="dark" className={color ? "NavBar NavBar-bg" : "NavBar"}>
                        <Container>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Brand href="#">
                                <div className='NavLogo'>
                                    <Link to="/" className='logo1'>
                                     <img src={Logo}  alt="logo" /> 
                                     </Link>
                                </div>
                            </Navbar.Brand>
                            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                                <Nav>
                                    <Nav.Link href="#home"><Link to="/"> Home</Link></Nav.Link>
                                    <Nav.Link href="#Gallery"><Link to="/best-interior-designers-mira-road">About us</Link></Nav.Link>
                                    <Nav.Link href="#Contact us"><Link to="/top-interior-designers-mira-road">Services</Link> </Nav.Link>
                                    <Nav.Link href="#Contact us"><Link to="/residential-interior-designer-mira-road"> Projects</Link></Nav.Link>      
                                    <Nav.Link href="#About us"> <Link to="/commercial-interior-designer-mira-road"> Contact us</Link> </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                            <Nav className="Social-media-icons">
                            </Nav>
                        </Container>
                    </Navbar>
                    {/* </div> */}
                </section>

            </div>
        </>
    )
}


export default Hadder
