import React from 'react'
import Hadder from '../Components/Hadder'
import Fotter from '../Components/fotter'
import { MetaTags } from 'react-meta-tags';
import { BiSolidBed} from 'react-icons/bi';
import { FaBuilding} from 'react-icons/fa';
import { MdPlumbing,MdOutlineDoorSliding} from 'react-icons/md';


import { MdElectricalServices} from 'react-icons/md';

import { AiFillFormatPainter} from 'react-icons/ai';
import {GiThermometerScale,GiStoneWall,GiTempleGate,} from 'react-icons/gi';
import { LuLampCeiling} from 'react-icons/lu';

import { LiaDraftingCompassSolid,LiaPlaySolid } from 'react-icons/lia';
const Servic = () => {
  return (
    <div>
         <Hadder></Hadder>
         <MetaTags>
        <title>    Top Interior Designer In Mira Road | Shree Vallabh Interior</title>
        <meta title="    Top Interior Designer In Mira Road | Shree Vallabh Interior" />
        <meta name="description" content="  Explore a curated list of the Top Interior Designers In Mira Road. Transform your space with expert home stylists specializing in innovative designs ." />
        <meta name="keywords" content="indias-best-celebrity-management-agency-mumbai,event-management-company-andheri,celebrity-management-company-andheri,top-event-management-company-andheri,best-celebrity-management-company-in-andheri" />
        <link rel="canonical" href="https://shreevallabhinterior.in/top-interior-designers-mira-road" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="    Top Interior Designer In Mira Road | Shree Vallabh Interior" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shreevallabhinterior.in/" />
        <meta property="og:description" content="  Explore a curated list of the Top Interior Designers In Mira Road. Transform your space with expert home stylists specializing in innovative designs ." />
        <meta property="og:image" content="https://shreevallabhinterior.in/static/media/LOGO.1844ee8b9261e65cff58.png" />
      </MetaTags>
        <div
  className="breadcrumb-area breadcrumb-padding bg-img"
  style={{ backgroundImage: "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-2.jpg)" }}
>
  <div className="container">
    <div className="breadcrumb-content text-center">
      <h1 style={{color:"white"}}>Top Interior Designer In Mira Road</h1>
      {/* <ul>
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>
          <i className="fa fa-angle-right" />
        </li>
        <li>SERVICES</li>
      </ul> */}
    </div>
  </div>
</div>
<div className='paddingtop'>
<div className="section section-padding pt-0">
  <div className="container">
    <div className="section-title text-center mb-lg-9 mb-md-7 mb-5">
      <h2 className="title">
        Our <span>Services</span>
      </h2>
      <p>
      At Shree Vallabh Interior Designs , we take pride in being recognized as one of the Top Interior Designers in Mira Road.
      </p>
    </div>
    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-1.jpg" alt="Interior Designer" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <LiaDraftingCompassSolid/>
              </div>
              <h3 className="title">
                <a href="#">Interior Designer</a>
              </h3>
              <p>
              Creating personalised places capturing your unique style and merging aesthetics with practicality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-2.jpg" alt="Civil Contractor" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <FaBuilding/>
              </div>
              <h3 className="title">
                <a href="#">Civil Contractor</a>
              </h3>
              <p>
              Constructing long lasting buildings is at the heart of our professional team , providing a foundation for durability and reliability.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-3.jpg" alt="Painting Contractor" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <AiFillFormatPainter/>
              </div>
              <h3 className="title">
                <a href="#">Painting Contractor </a>
              </h3>
              <p>
              Transforming walls into canvases , our professional team brings vision to life with vibrant colour pallets.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-4.jpg" alt="Electrical Work" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <MdElectricalServices />
              </div>
              <h3 className="title">
                <a href="#">Electrical Work </a>{" "}
              </h3>
              <p>
              ntegrating lighting designs with finesse , our professional electricians enhance the appearance of your interiors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-5.jpg" alt="Plumbing Work" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <MdPlumbing/>
              </div>
              <h3 className="title">
                <a href="#">
                Plumbing Work</a>
              </h3>
              <p>
              Installing to repairing , our professional plumbers ensure a seamless and hassle-free water flow in your places.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-6.jpg" alt="Aluminium Window" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <div className="dlicon">
                <MdOutlineDoorSliding/>
                </div>
              </div>
              <h3 className="title">
                <a href="#">Aluminium Window</a>{" "}
              </h3>
              <p>
                
Experiencing elegance and efficiency with our aluminium window installations.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<Fotter></Fotter>
    </div>
  )
}

export default Servic
