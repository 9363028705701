
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hadder from './Components/Hadder';
import Home from "./pages/Home"
import Aboutus from './pages/Aboutus';
import Servic from './pages/Servic';
import Contectus from './pages/Contectus';
import Project from './pages/project';
import What from './Components/web';
import Error from './pages/error';
function App() {
  return (
    <div className="App">
     
      <BrowserRouter> 
      <What></What>
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/best-interior-designers-mira-road' element={<Aboutus/>}></Route>
     <Route path='/top-interior-designers-mira-road' element={<Servic/>}></Route>
     <Route path='/residential-interior-designer-mira-road' element={<Project/>}></Route>
     <Route path='/commercial-interior-designer-mira-road' element={<Contectus/>}></Route>
     <Route path='/*' element={<Error/>}></Route>
      </Routes>

    </BrowserRouter>
   
    </div>
  );
}

export default App;
