import React from 'react'
import LOGO from "../assets/LOGO.png"
import { Link } from "react-router-dom";
import { LiaFacebookF,LiaTwitter,LiaInstagram } from 'react-icons/lia';
const Fotter = () => {
  return (
    <div>
      <footer className="section bg-black">
  <div className="footer-top section-padding">
    <div className="container">
      <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n8">
        <div className="col mb-8">
          <div className="footer-widget footer-about">
            <div className="footer-logo textalign">
              <a href="#">
                <Link to="/" >
                <img className='footerlogo ' src={LOGO} alt="logo" />
                </Link>
              </a>
            </div>
            <p className='textalign'>
            Curating interiors that breathe life into rooms, blending style and comfort seamlessly.
            </p>
            <div className="social-icon-style textalign">
              
              <a className="google-plus" href="https://www.instagram.com/shree_vallabh_interior/">
        
                <div className='fa1'>
                <LiaInstagram />
                </div>
              </a>
           
            </div>
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-list">
            <h3 className="footer-title textalign">Useful Link</h3>
            <ul>
              <li>
                <a ><Link to="/"> Home</Link></a>
              </li>
              <li>
                <a ><Link to="/best-interior-designers-mira-road">About us</Link></a>
              </li>
              <li>
                <a ><Link to="/top-interior-designers-mira-road">Services</Link></a>
              </li>
              <li>
                <a ><Link to="/residential-interior-designer-mira-road"> Projects</Link></a>
              </li>
              <li>
                <a > <Link to="/commercial-interior-designer-mira-road"> Contact us</Link> </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-list">
            <h3 className="footer-title textalign">Contact Us</h3>
            <ul>
              <li style={{color:"white"}}>
                <span className="title">T:</span>
                <span className="desc textalign">
+919920706747</span>/<span className="desc textalign">+918369001670</span>
              </li>
              <li>
                <span className="title">E:</span>
                <span className="desc textalign">
                info@shreevallabhinterior.in
                </span>
              </li>
              <li>
                <span className="title">A:</span>
                <span className="desc textalign">
                  
Shop no. 8, Salasar Ashirwad Ramdev Park road near LR tiwar college, Mira road (E)401107
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-newsletter">
            <h3 className="footer-title textalign">Newsletter</h3>
            <p className='textalign'>
              <div style={{borderRadius:"25px"}}>
              <iframe style={{borderRadius:"25px"}}
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d481992.49353867094!2d72.874707!3d19.3012153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b03d64ea8f35%3A0x4459dbb6413ea163!2sSalasar%20Aashirwad!5e0!3m2!1sen!2sin!4v1691040480691!5m2!1sen!2sin"/>
              </div>
            </p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-bottom">
    <div className="container">
      <div className="copyright text-center">
       
        <div className='Copy-Right'>
                    <div className='Contact-Us-Copy' style={{color:"white"}}>
                        © Copyright
                        <strong>
                            <span style={{ color: "#ea622b" }}>Shree vallabh Interior</span>
                        </strong>
                        . All Rights Reserved
                    </div>
                    <div className="credits" style={{color:"white"}}>
                        Designed by
                        <a href="https://skdm.in/" style={{ color: "#ea622b" }}>
                            Shree Krishna Digital
                        </a>
                    </div>
                </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Fotter
