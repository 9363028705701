import React, { useState } from "react";
import Hadder from '../Components/Hadder'
import hader from "../assets/hader.mp4"
import Menu from "../Components/Menu"
import h2 from "../assets/homebackground.jpg"
import "../css/style.css"
import Fotter from "../Components/fotter";
import NumberCounter from 'number-counter'; 
import i1 from "../assets/i1.png"
import i2 from "../assets/i2.png"
import i3 from "../assets/i3.png"
import i4 from "../assets/i4.png"
import aa1 from "../assets/aa11.png"
import h1 from "../assets/h1.png"
import h7 from "../assets/h2.png"
import h3 from "../assets/h3.png"
import h4 from "../assets/h4.png"
import h5 from "../assets/h5.png"
import h6 from "../assets/h6.png"
import pre2 from "../assets/project/pre2.jpg"
import pre3 from "../assets/project/pre3.jpg"
import pre4 from "../assets/project/pre4.jpg"
import pre5 from "../assets/project/pre5.jpg"
import pre6 from "../assets/project/pre6.jpg"
import pre7 from "../assets/project/pre7.jpg"
import { Link } from "react-router-dom";
import { BiSolidBed} from 'react-icons/bi';
import { FaBuilding} from 'react-icons/fa';
import { MetaTags } from 'react-meta-tags';
import { MdPlumbing,MdOutlineDoorSliding} from 'react-icons/md';


import { MdElectricalServices} from 'react-icons/md';

import { AiFillFormatPainter} from 'react-icons/ai';

import {GiThermometerScale,GiStoneWall,GiTempleGate,} from 'react-icons/gi';
import { LuLampCeiling} from 'react-icons/lu';
import { LiaDraftingCompassSolid,LiaPlaySolid } from 'react-icons/lia';
import GalleryReact from "./project";
const Home = () => {
  const [items, setItems] = useState(Menu);
  const [active, setActive] = useState(false);
  const filterItem = (categItem) => {
    const updateItems = Menu.filter((curElem) => {
      return curElem.category === categItem;
    });

    setItems(updateItems);
    setActive(true);
  };
  
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title> Interior Designer In Mira Road | Shree Vallabh Interior</title>
        <meta title=" Interior Designer In Mira Road | Shree Vallabh Interior" />
        <meta name="description" content="  Best Interior Designer In Mira Road Shree Vallabh Interior transform your living space with the expertise team and bring your dream home to life." />
        <meta name="keywords" content="interior-designer-mira-road,best-interior-designers-mira-road,top-interior-designers-mira-road, residential-interior-designer-mira-road,commercial-interior-designer-mira-road" />
        <link rel="canonical" href="https://shreevallabhinterior.in/interior-designer-mira-road" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Interior Designer In Mira Road | Shree Vallabh Interior" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shreevallabhinterior.in/" />
        <meta property="og:description" content="  Best Interior Designer In Mira Road Shree Vallabh Interior transform your living space with the expertise team and bring your dream home to life." />
        <meta property="og:image" content="https://shreevallabhinterior.in/static/media/LOGO.1844ee8b9261e65cff58.png" />
      </MetaTags>
      <div className="hero-area overly-style-1 opacity-point-4">
  <video className="video-active" autoPlay="autoplay" loop="loop" muted="muted">
    <source src={hader} type="video/mp4" />
  </video>
  <div className="hero-content-1 hero-content-position">
    <h1
      className="title aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay={200}
    >
      Interior Designer In Mira Road 
    </h1>
    <p data-aos="fade-up" data-aos-delay={300} className="aos-init aos-animate">
    Our passion lies in transforming spaces into works of art and as your trusted Interior Designer in Mira Road are here to bring visions to life{" "}
    </p>
    <a
     
      className="btn btn-primary btn-hover-dark aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay={400}
    >
     <Link to="/residential-interior-designer-mira-road">VIEW PROJECT</Link>
      
    </a>
  </div>
</div>
{/* about us  start*/}

<div className="section section-padding">
  <div className="container">
    <div className="row mb-n6">
      <div className="col-md-6 mb-6 align-self-center order-md-1 order-2">
        <div className="about-content  margintop10">
          <h2 className="title textalign">
            Welcome to <span><span>Shree vallabh Interior </span></span>
          </h2>
          <p className='textalign'>
          We, Interior Designers in Mira Road craft interiors from concept to reality curating comfort and captivating aesthetics , where every room is a canvas and your dreams are the masterpiece. 

          </p>
          <h3 className="sub-title textalign">
         
          </h3>
        </div>
        <div className="row row-cols-md-3 row-cols-sm-3 row-cols-2 textalign">
          <div className="col mb-6">
            <div className="funfact">
              <span
                className="count-style odometer odometer-auto-theme"
                data-count-to={594}
              >
                <div className="odometer-inside">
                  <span className="odometer-digit">
                    <span className="odometer-digit-inner">
                      <span className="odometer-ribbon">
                        <span className="odometer-ribbon-inner">
                          <span className="odometer-value"><NumberCounter end={1000} delay={4}/></span>
                        </span>
                      </span>
                    </span>
                  </span>
                 
                </div>
              </span>
              <h4 className="title">Projects Worked</h4>
            </div>
          </div>
          <div className="col mb-6">
            <div className="funfact">
              <span
                className="count-style odometer odometer-auto-theme"
                data-count-to={100}
              >
                <div className="odometer-inside">
                  <span className="odometer-digit">
                    <span className="odometer-digit-inner">
                      <span className="odometer-ribbon">
                        <span className="odometer-ribbon-inner">
                          <span className="odometer-value"><NumberCounter end={1000} delay={4}/></span>
                        </span>
                      </span>
                    </span>
                  </span>
                 
                </div>
              </span>
              <h4 className="title">Expert Workers</h4>
            </div>
          </div>
          <div className="col mb-6">
            <div className="funfact">
              <span
                className="count-style odometer odometer-auto-theme"
                data-count-to={785}
              >
               <div className="odometer-inside">
                  <span className="odometer-digit">
                    <span className="odometer-digit-inner">
                      <span className="odometer-ribbon">
                        <span className="odometer-ribbon-inner">
                          <span className="odometer-value"><NumberCounter end={1000} delay={4}/></span>
                        </span>
                      </span>
                    </span>
                  </span>
                 
                </div>
              </span>
              <h4 className="title">Happy Clients</h4>
            </div>
          </div>
        </div>
        <div className='textalign paddingtop'>
        <a  className="btn btn-dark ">
         <Link to="/best-interior-designers-mira-road">LEARN MORE</Link> 
        </a>
        </div>
      </div>
      <div className="col-md-6 mb-6 order-md-2 order-md-2 order-1">
        <div className="about-banner">
          <img src={aa1} alt="17 YEARS OF EXPERIENCE in interior design" />
        </div>
      </div>
    </div>
  </div>
</div>



{/* about us  start*/}
{/*service*/}
<div className="section section-padding pt-0">
  <div className="container">
    <div className="section-title text-center mb-lg-9 mb-md-7 mb-5">
      <h2 className="title">
        Our <span>Services</span>
      </h2>
      <p>
      At Shree Vallabh Interior Designs , we take pride in being recognized as one of the Top Interior Designers in Mira Road.
      </p>
    </div>
    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-1.jpg" alt="BEST nterior Designer in mira road" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <LiaDraftingCompassSolid/>
              </div>
              <h3 className="title">
                <a href="#">Interior Designer</a>
              </h3>
              <p>
              Creating personalised places capturing your unique style and merging aesthetics with practicality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-2.jpg" alt="best Civil Contractor in mira road" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <FaBuilding/>
              </div>
              <h3 className="title">
                <a href="#">Civil Contractor</a>
              </h3>
              <p>
              Constructing long lasting buildings is at the heart of our professional team , providing a foundation for durability and reliability.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-3.jpg" alt="best Painting Contractor in mira road " />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <AiFillFormatPainter/>
              </div>
              <h3 className="title">
                <a href="#">Painting Contractor </a>
              </h3>
              <p>
              Transforming walls into canvases , our professional team brings vision to life with vibrant colour pallets.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-4.jpg" alt="best Electrical Work in mira road " />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <MdElectricalServices />
              </div>
              <h3 className="title">
                <a href="#">Electrical Work </a>{" "}
              </h3>
              <p>
              ntegrating lighting designs with finesse , our professional electricians enhance the appearance of your interiors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-5.jpg" alt="best Plumbing Work in mira road" />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <MdPlumbing/>
              </div>
              <h3 className="title">
                <a href="#">
                Plumbing Work</a>
              </h3>
              <p>
              Installing to repairing , our professional plumbers ensure a seamless and hassle-free water flow in your places.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col mb-6 marginbottom">
        <div className="service text-center">
          <img src="https://htmldemo.net/drior/drior/assets/images/service/service-6.jpg" alt="best aluminium window in mira road " />
          <div className="service-content-wrap">
            <div className="service-content">
              <div className="service-icon">
                <div className="dlicon">
                <MdOutlineDoorSliding/>
                </div>
              </div>
              <h3 className="title">
                <a href="#">Aluminium Window</a>{" "}
              </h3>
              <p>
                
Experiencing elegance and efficiency with our aluminium window installations.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/*service*/}
<div
  className="section section-padding bg-img overly-style-1 opacity-point-7"
  style={{ backgroundImage: "url(	https://htmldemo.net/drior/drior/assets/images/bg/bg-1.jpg)" }}
>
  <div className="container">
    <div className="section-title text-center mb-lg-9 mb-md-7 mb-5">
      <h2 className="title white">
      Our Working <span>Process</span>
      </h2>
      <p className="white">
      As a leading Interior Designers in Mira Road , we pride ourselves on the working process that transforms your visions into stunning realities.
      </p>
    </div>
    <div className="row">
      <div className="col-md-4 padding-bottom20 mb-6">
        <div className="working-process text-center">
          <img className="iconwidth" src={h1} alt="Initial Process" />
          <h4 className="title">Initial Process</h4>
          <p>Our experts visit project sites to understand the requirements and explain the process</p>
        </div>
      </div>
      <div className="col-md-4 padding-bottom20 mb-6">
        <div className="working-process text-center">
        <img className="iconwidth"  src={h7} alt="Quotation" />
          <h4 className="title">Quotation</h4>
          <p> Our team makes a detailed quotation listing all the prices and materials as we believe in transparency</p>
        </div>
      </div>
      <div className="col-md-4 padding-bottom20  mb-6">
        <div className="working-process text-center">
        <img className="iconwidth"  src={h3} alt="3D Designing & Virtual Reality" />
          <h4 className="title">3D Designing & Virtual Reality</h4>
          <p>We create 3D design & Virtual Reality as per Clients taste and requirement for a better visualisation of the project</p>
          <p></p>
        </div>
      </div>
      <div className="col-md-4 padding-bottom20 mb-6">
        <div className="working-process text-center">
        <img className="iconwidth"  src={h4} alt="Material Selection" />
          <h4 className="title">Material Selection</h4>
          <p>Our designers help Clients to select the materials and finishes as per our theme and design</p>
        </div>
      </div>
      <div className="col-md-4 padding-bottom20 mb-6">
        <div className="working-process text-center">
        <img className="iconwidth"  src={h5} alt="Execution"/>
          <h4 className="title">Execution</h4>
          <p>After designing process, we step ahead for the execution of the project</p>
        </div>
      </div>
      <div className="col-md-4 padding-bottom20 mb-6">
        <div className="working-process text-center">
        <img className="iconwidth"  src={h6} alt="Final Handover" />
          <h4 className="title">Final Handover</h4>
          <p>After executing in the discussed time period, the site is handed over to the clients</p>
        </div>
      </div>
    </div>
    
  </div>
</div>
<div className="project1">
<div className="section section-padding">
  <div className="container">
    <div className="section-title text-center mb-lg-8 mb-md-6 mb-4">
      <h2 className="title">
      Our <span> Projects</span>
      </h2>
      <p>
      Our projects showcase the artwork and professionalism that defines us as the premier Residential Interior Designer In Mira Road.

      </p>
    </div>
    
    <div style={{ position: "relative" }} >
      <div className='row'>
      <div className="col-md-4 mb-6 grid-item cat2 cat4">
        <div className="project-wrap marginbottom10"> 
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre7} alt="Building" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Building</a>
              </span>
              <h3 className="title">
                <a >Living Room Interior Design</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-6 grid-item cat1">
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre4} alt="Interior" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Interior</a>
              </span>
              <h3 className="title">
                <a >
                  Coffee &amp; Restaurant Interior Design
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
     
     <div className="col-md-4 mb-6 grid-item cat4" >
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre6} alt="Architecture" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Architecture</a>
              </span>
              <h3 className="title">
                <a >Bedroom Remodeling </a>
              </h3>
            </div>
          </div>
        </div>
      </div>

     
      </div>

    </div>
    <div className="project-btn mt-6 mt-md-8 mt-lg-10 text-center">
      <a href="#" className="btn btn-dark btn-hover-primary">
        MORE PROJECT
      </a>
    </div>
  </div>
</div>
</div>
<div className="project2">
<div className="section section-padding">
  <div className="container">
    <div className="section-title text-center mb-lg-8 mb-md-6 mb-4">
      <h2 className="title">
        Our <span> Projects</span>
      </h2>
      <p>
      Our projects showcase the artwork and professionalism that defines us as the premier Residential Interior Designer In Mira Road.
      </p>
    </div>
    
    <div className='row'>
      <div className="col-md-4 mb-6 grid-item cat2 cat4">
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre7} alt="Building" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Building</a>
              </span>
              <h3 className="title">
                <a >Living Room Interior Design</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-6 grid-item cat1">
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre4} alt="Interior" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Interior</a>
              </span>
              <h3 className="title">
                <a >
                  Coffee &amp; Restaurant Interior Design
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
     
     <div className="col-md-4 mb-6 grid-item cat4" >
        <div className="project-wrap marginbottom10">
          <div className="project-inner">
            <a className="thumbnail" >
              <img src={pre6} alt="Architecture" />
            </a>
            <div className="project-content">
              <span className="category">
                <a href="#">Architecture</a>
              </span>
              <h3 className="title">
                <a >Bedroom Remodeling </a>
              </h3>
            </div>
          </div>
        </div>
      </div>

     
      </div>
    <div className="project-btn mt-6 mt-md-8 mt-lg-10 text-center">
      <a  className="btn btn-dark btn-hover-primary">
      <Link to="/residential-interior-designer-mira-road">
        MORE PROJECT
        </Link>
      </a>
    </div>
  </div>
</div>
</div>

<div className="section video-banner">
  <div className="banner-content">
    <video preload="none" loop="loop" poster={h2}>
      <source src="assets/video/video.mp4" type="video/mp4" />
    </video>
    <div className="status play-pause-style">
      
      <div className="fa2">
      <LiaPlaySolid/>
      </div>
    </div>
  </div>
</div>

<Fotter></Fotter>
    </div>
  )
}

export default Home
